export const useAutoFocusable = (controller, options = {}) => {
  Object.assign(controller, {
    setFocus(input) {
      requestAnimationFrame(() => {
        input.focus();
      });
    }
  });

  const inputs = controller.element.querySelectorAll('input[type="text"], input[type="email"], input[type="number"], textarea');

  for (let input of inputs) {
    if (input.value === '' && input.tabIndex > -1 &&
      input.getAttribute('data-multi-select-target') == null &&
      input.getAttribute('data-remote-multi-select-target') == null) {
      controller.setFocus(input);

      break;
    }
  }
};
