import {Controller} from "stimulus"

const RADIO_BUTTON_TOGGLE = 'radio-button-selection-toggle'

export default class extends Controller {
  static targets = ["radioButton"]

  connect() {
  }

  radioChanged(event) {
    event.stopImmediatePropagation()

    const selectionEvent = new CustomEvent(RADIO_BUTTON_TOGGLE, {
      detail: {
        name: event.target.name,
        value: event.target.value
      }
    });

    document.dispatchEvent(selectionEvent);
    return false;
  }
}
