import { Controller } from 'stimulus';
import { useDispatch, useTransition } from 'stimulus-use';

import { findParentWith } from '../../lib/dom';

export default class extends Controller {
  connect() {
    useDispatch(this);
    useTransition(this);

    const transitionEnter = this.enter;
    const transitionLeave = this.leave;

    const modalTarget = findParentWith(
      this.element, e => e.dataset && e.dataset.controller === 'modal'
    );

    this.enter = async (ev) => {
      // Explicit modalId and modal controller, limit to only matching modal
      if (ev.detail.hasOwnProperty('modalId') && modalTarget) {
        if (ev.detail.modalId !== modalTarget.dataset.modalId) {
          return;
        }
      }

      // Explicit target element, limit to only matching element
      if (ev.detail.hasOwnProperty('target')) {
        if (ev.detail.target !== this.element)
          return;
      }

      await transitionEnter();
    };

    // TODO ensure only the correct modal is leaving. for instance if a flash
    // is open and edit modal, both will close when either is closed.
    this.leave = async (ev) => {
      // Explicit target element, limit to only matching element
      if (ev.detail.hasOwnProperty('target')) {
        if (ev.detail.target !== this.element)
          return;
      }

      await transitionLeave();

      this.dispatch('finished');
    };
  }
}
