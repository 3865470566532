import { Controller } from 'stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['section'];
  static values = {
    initPosition: String
  }

  connect() {
    if (this.initPositionValue == "up") {
      this.sectionTarget.classList.add('hidden')
    };
  }

  slideUp() {
    this.sectionTarget.style.transitionProperty = 'height, margin, padding';
    this.sectionTarget.style.transitionDuration = 800 + 'ms';
    this.sectionTarget.style.boxSizing = 'border-box';
    this.sectionTarget.style.height = this.sectionTarget.offsetHeight + 'px';
    this.sectionTarget.offsetHeight;
    this.sectionTarget.style.overflow = 'hidden';
    this.sectionTarget.style.height = 0;
    this.sectionTarget.style.paddingTop = 0;
    this.sectionTarget.style.paddingBottom = 0;
    this.sectionTarget.style.marginTop = 0;
    this.sectionTarget.style.marginBottom = 0;
    window.setTimeout( () => {
          this.sectionTarget.style.display = 'none';
          this.sectionTarget.style.removeProperty('height');
          this.sectionTarget.style.removeProperty('padding-top');
          this.sectionTarget.style.removeProperty('padding-bottom');
          this.sectionTarget.style.removeProperty('margin-top');
          this.sectionTarget.style.removeProperty('margin-bottom');
          this.sectionTarget.style.removeProperty('overflow');
          this.sectionTarget.style.removeProperty('transition-duration');
          this.sectionTarget.style.removeProperty('transition-property');
    }, 800);
  }

  slideDown() {
      this.sectionTarget.style.removeProperty('display');
      let display = window.getComputedStyle(this.sectionTarget).display;
      if (display === 'none') display = 'block';
      this.sectionTarget.style.display = display;
      let height = this.sectionTarget.offsetHeight;
      this.sectionTarget.style.overflow = 'hidden';
      this.sectionTarget.style.height = 0;
      this.sectionTarget.style.paddingTop = 0;
      this.sectionTarget.style.paddingBottom = 0;
      this.sectionTarget.style.marginTop = 0;
      this.sectionTarget.style.marginBottom = 0;
      this.sectionTarget.offsetHeight;
      this.sectionTarget.style.boxSizing = 'border-box';
      this.sectionTarget.style.transitionProperty = "height, margin, padding";
      this.sectionTarget.style.transitionDuration = 800 + 'ms';
      this.sectionTarget.style.height = height + 'px';
      this.sectionTarget.style.removeProperty('padding-top');
      this.sectionTarget.style.removeProperty('padding-bottom');
      this.sectionTarget.style.removeProperty('margin-top');
      this.sectionTarget.style.removeProperty('margin-bottom');
      window.setTimeout( () => {
        this.sectionTarget.style.removeProperty('height');
        this.sectionTarget.style.removeProperty('overflow');
        this.sectionTarget.style.removeProperty('transition-duration');
        this.sectionTarget.style.removeProperty('transition-property');
      }, 800);
  }
}
