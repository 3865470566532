import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    autoGrow: Boolean, copy: Object, limit: Number, minimum: Number
  };

  connect() {
    this.maxRows = this.element.rows * 4;
    this.element.maxLength = this.limitValue;

    this.adjustTextArea();
    this.renderStatus();
  }

  disconnect() {
    if (this.statusNode) {
      this.statusNode.remove();
    }
  }

  adjustTextArea() {
    if (this.hasAutoGrowValue && this.autoGrowValue) {
      const rect = this.element.getBoundingClientRect();

      if (this.element.rows >= this.maxRows)
        return;

      if (rect.height < this.element.scrollHeight) {
        this.element.rows += 1;

        requestAnimationFrame(() => {
          this.adjustTextArea();
        });
      }
    }
  }

  // TODO deprecate this method
  renderLimit() {
    this.renderStatus();
  }

  renderStatus() {
    if (!this.hasLimitValue)
      return;

    if (!this.statusNode) {
      this.statusNode = document.createElement('span');
      this.statusNode.setAttribute('class', 'text-gray-900 text-sm leading-none text-right block h-4');
      this.element.parentElement.appendChild(this.statusNode);
    }

    const remaining = this.limitValue - this.element.value.length;

    this.statusNode.classList.remove('!text-indicator-red');

    if (this.hasMinimumValue && (this.element.value.length <= this.minimumValue)) {
      this.statusNode.classList.remove('opacity-0');


      if (this.element.value.length < this.minimumValue) {
        const minRemaining = this.minimumValue - this.element.value.length;

        if (minRemaining > 1) {
          this.statusNode.textContent = `${minRemaining} ${this.copyValue.minimum.other}`;
        } else {
          this.statusNode.textContent = `1 ${this.copyValue.minimum.one}`;
        }
      } else {
        this.statusNode.classList.add('opacity-0');
      }
    } else {
      if (this.element.value.length <= this.limitValue) {
        this.statusNode.classList.add('opacity-0')

        if (remaining < 1) {
          this.statusNode.classList.remove('opacity-0')
          this.statusNode.textContent = this.copyValue.limit.zero;
          this.statusNode.classList.add('!text-indicator-red');
        }
      }
    }
  }


  change = () => {
    this.adjustTextArea();
    this.renderStatus();
  }
}
