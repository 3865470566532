import { Controller } from 'stimulus';
import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static values = {
    trigger: String
  };

  connect() {
    this.element.classList.add('animate-pulse');
    this.listener = new ElementListener(this.element, this.triggerValue, this.onStopPulse);
  }

  disconnect() {
    this.listener.remove();
  }

  onStopPulse = () => {
    this.element.classList.remove('animate-pulse');
  }
}
