export function getMetaContent(name) {
  const meta = document.querySelector(`meta[name="${name}"]`);

  if (meta) {
    return meta.content;
  }
}

export function findParentWith(element, callback) {
  for (let i = 0; i < 10; i++) {
    if (!element)
      return;

    if (callback(element))
      return element;

    element = element.parentElement;
  }
}
