import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sectionOne', 'sectionTwo', 'sectionThree', 'sectionFour'];
  static values = {
    initView: String
  }

  connect() {
    if (this.initViewValue == "sectionTwo") {
      this.sectionTwoTarget.classList.remove('hidden');
      this.sectionTwoTarget.style.opacity = 1;
      this.sectionThreeTarget.classList.remove('hidden');
      this.sectionThreeTarget.style.opacity = 1;
      this.sectionOneTarget.classList.add('hidden');
      this.sectionOneTarget.style.opacity = 0;
      this.sectionFourTarget.classList.add('hidden');
      this.sectionFourTarget.style.opacity = 0;
    };
  }

  viewSectionOne() {
    this.sectionOneTarget.style.transition = 600 + 'ms';
    this.sectionOneTarget.style.opacity = 1;
    this.sectionFourTarget.style.transition = 600 + 'ms';
    this.sectionFourTarget.style.opacity = 1;
    this.sectionTwoTarget.style.transition = 600 + 'ms';
    this.sectionTwoTarget.style.opacity = 0;
    this.sectionThreeTarget.style.transition = 600 + 'ms';
    this.sectionThreeTarget.style.opacity = 0;
    window.setTimeout( () => {
          this.sectionOneTarget.classList.remove('hidden');
          this.sectionFourTarget.classList.remove('hidden');
          this.sectionTwoTarget.classList.add('hidden');
          this.sectionThreeTarget.classList.add('hidden');
    }, 600);
  }

  viewSectionTwo() {
    this.sectionOneTarget.style.transition = 600 + 'ms';
    this.sectionOneTarget.style.opacity = 0;
    this.sectionFourTarget.style.transition = 600 + 'ms';
    this.sectionFourTarget.style.opacity = 0;
    this.sectionTwoTarget.style.transition = 600 + 'ms';
    this.sectionTwoTarget.style.opacity = 1;
    this.sectionThreeTarget.style.transition = 600 + 'ms';
    this.sectionThreeTarget.style.opacity = 1;
    window.setTimeout( () => {
          this.sectionTwoTarget.classList.remove('hidden');
          this.sectionThreeTarget.classList.remove('hidden');
          this.sectionOneTarget.classList.add('hidden');
          this.sectionFourTarget.classList.add('hidden');
    }, 600);
  }
}
