import { Controller } from 'stimulus';
import { useClickOutside, useDispatch, useHotkeys, useTransition } from 'stimulus-use';

import ElementListener from '../../lib/element_listener';

// TODO
// set aria-controls attribute
// set aria-selected attribute
// set aria-activedescendant="headlessui-listbox-option-60" as focus changes

export default class extends Controller {
  static targets = ['arrow', 'button', 'menu', 'menuItem', 'overlay'];

  static values = {
    itemFocusClass: String,
  };

  connect() {
    this.focusedIndex = -1;
    this.itemFocusClasses = this.itemFocusClassValue.split(' ');

    this.listener = new ElementListener(this.element, 'click', ev => {
      if (this.opened) {
        const path = event.composedPath ? event.composedPath() : event.path;

        if ([...path].find(e => e && e.role === 'none')) {
          this.closeMenu();
        }
      }
    });

    useClickOutside(this);
    useDispatch(this);

    // https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
    // https://headlessui.dev/react/menu#keyboard-interaction
    useHotkeys(this, {
      'Enter': [this.onFocus],
      'Space': [this.onFocus],
      'Down': [this.onNextMenuItem],
      'Up': [this.onPreviousMenuItem],
      'Right': [this.onNextMenuItem],
      'Left': [this.onPreviousMenuItem],
      'Escape': [this.onEscapePress],
      'Tab': [this.onTabPress],
      'Shift+Tab': [this.onTabPress],
    });

    useTransition(this, {
      element: this.menuTarget
    });
  }

  disconnect() {
    this.updateOverlay(false);
    this.listener.remove();
  }

  openMenu() {
    console.log('openMenu', this.menuTarget);

    this.buttonTarget.setAttribute('aria-expanded', 'true');
    this.buttonTarget.setAttribute('aria-controls', this.menuTarget.id);
    this.updateOverlay(true);

    this.buttonTarget.classList.remove('text-prussian-700');
    this.buttonTarget.classList.add('bg-snow-800', 'border-prussian-700');

    if (this.hasArrowTarget) {
      this.arrowTarget.classList.add('-rotate-180');
    }

    requestAnimationFrame(async () => {
      await this.enter();

      this.opened = true;
    });
  }

  closeMenu() {
    console.log('closeMenu', this.menuTarget);

    if (!this.opened)
      return;

    this.focusedIndex = -1;
    this.opened = false;

    this.buttonTarget.setAttribute('aria-expanded', 'false');
    this.buttonTarget.removeAttribute('aria-controls');

    this.updateFocus();
    this.updateOverlay(false);

    this.buttonTarget.classList.add('text-prussian-700');
    this.buttonTarget.classList.remove('bg-snow-800', 'border-prussian-700');

    if (this.hasArrowTarget) {
      this.arrowTarget.classList.remove('-rotate-180');
    }

    requestAnimationFrame(() => {
      this.leave();
    });
  }

  updateFocus() {
    console.log('updateFocus', this.focusedIndex);

    this.menuItemTargets.forEach(item => item.classList.remove(...this.itemFocusClasses));

    if (this.focusedIndex > -1) {
      const item = this.menuItemTargets[this.focusedIndex]

      item.classList.add(...this.itemFocusClasses);
      item.focus();
    }
  }

  updateOverlay(visible) {
    if (this.hasOverlayTarget) {
      if (visible) {
        this.overlayTarget.classList.remove('hidden', 'opacity-0', 'pointer-events-none');

        document.body.classList.add('overflow-y-hidden');
      } else {
        this.overlayTarget.classList.add('opacity-0', 'hidden', 'pointer-events-none');

        document.body.classList.remove('overflow-y-hidden');
      }
    }
  }

  blur() {
    console.log('blur');

    this.focusedIndex = -1;
    this.updateFocus();
  }

  focusFirstItem() {
    console.log('focusFirstItem');

    this.focusedIndex = 0;
    this.updateFocus();
  }


  focusItem(ev) {
    console.log('focusItem', ev);

    this.focusedIndex = this.menuItemTargets.findIndex(item => item === ev.target);
    this.updateFocus();
  }

  clickOutside(ev) {
    if (this.transitioned) {
      if (ev.target instanceof HTMLButtonElement && ev.target.type === 'submit') {
        this.closeMenu();

      } else {
        ev.preventDefault();

        this.closeMenu();
      }
    }
  }

  onClick() {
    console.log('onClick');

    if (this.transitioned) this.closeMenu();
    else this.openMenu();
  }

  onEscapePress() {
    console.log('onEscapePress');

    this.closeMenu();
  }

  onFocus(ev) {
    if (this.hasButtonTarget && this.buttonTarget === document.activeElement) {
      ev.preventDefault();

      this.openMenu();
      this.focusFirstItem();
    }
  }

  onTabPress() {
    console.log('onTabPress');

    setTimeout(() => {
      if (this.focusedIndex > -1) {
        this.closeMenu();
      }
    }, 1);
  }

  onNextMenuItem(ev) {
    console.log('onNextMenuItem');

    if (this.focusedIndex > -1) {
      this.focusedIndex++;

      if (this.focusedIndex == this.menuItemTargets.length) {
        this.focusedIndex = 0; // wrap focus around
      }

      ev.preventDefault();
      this.updateFocus();
    }
  }

  onPreviousMenuItem(ev) {
    console.log('onPreviousMenuItem');

    if (this.focusedIndex > -1) {
      this.focusedIndex--;

      if (this.focusedIndex < 0) {
        this.focusedIndex = this.menuItemTargets.length - 1; // wrap focus around
      }

      ev.preventDefault();
      this.updateFocus();
    }
  }
}
