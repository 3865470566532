import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import { getMetaContent } from './dom';

(!function() {
  const dsn = getMetaContent('sentry_dsn');

  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for
      // performance monitoring.  We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  } else {
    console.debug('No error repoerting loaded');
  }
}());
