import { Controller } from 'stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['lever', 'select'];
  static values = {
    initStatus: String
  }

  connect() {
    useDispatch(this);

    if (this.initStatusValue == "on") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  toggle() {
    if (this.status == "off") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  turnOn() {
    this.selectTarget.selectedIndex = 0
    this.dispatch('selectChanged');
    this.status = "on"
  }

  turnOff() {
    this.selectTarget.selectedIndex = 1
    this.dispatch('selectChanged');
    this.status = "off"
  }
}
