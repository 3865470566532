import { Controller } from "stimulus";
import { useDispatch, useHotkeys, useTransition } from 'stimulus-use';

import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static targets = ['modal'];
  static values = {
    opened: Boolean,
    disableScroll: Boolean,
  };

  connect() {
    this.opened = this.openedValue;

    this.listeners = [
      new ElementListener(document.documentElement, 'application:openModal', this.onModalOpen),
      new ElementListener(document.documentElement, 'application:closeModal', this.onModalClose),
    ];

    useDispatch(this);

    // https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
    // https://headlessui.dev/react/dialog#keyboard-interaction
    useHotkeys(this, {
      'Tab': [this.onNextFocusItem],
      'Shift+Tab': [this.onPreviousFocusItem],
      'Escape': [this.onEscape]
    });

    if (this.opened) {
      if (this.hasDisableScrollValue && this.disableScrollValue !== false) {
        document.body.classList.add('overflow-y-hidden');
      } else if (!this.hasDisableScrollValue) {
        document.body.classList.add('overflow-y-hidden');
      }

      requestAnimationFrame(() => {
        this.dispatch('opened', { modalId: this.id });
      });
    }
  }

  disconnect() {
    this.listeners.forEach(l => l.remove());
  }

  get focusableElementsAndIndex() {
    const elements = Array.from(this.element.querySelectorAll(
      'button, a[href], input[type="text"], input[type="number"], input[type="email"], textarea'
    ));

    return [
      elements, elements.findIndex(e => e === document.activeElement)
    ];
  }

  get id() {
    return this.data.get('id');
  }

  open() {
    if (!this.hasModalTarget) {
      console.warn('ModalController is missing its "modal" target', this.element);

      return;
    }

    document.body.classList.add('overflow-y-hidden');

    this.opened = true;
    this.dispatch('opened', { modalId: this.id });
  }

  close(ev) {
    if (!this.opened)
      return;

    if (ev && ev.preventDefault && ev.target.type !== 'submit')
      ev.preventDefault();

    document.body.classList.remove('overflow-y-hidden');

    this.opened = false;
    this.dispatch('closed', { modalId: this.id });
  }

  remove() {
    this.element.remove();
  }

  onModalOpen = (ev) => {
    if (ev.detail.modalId === this.element.dataset.modalId) {
      this.open();
    }
  }

  onModalClose = (ev) => {
    if (ev.detail.modalId === this.element.dataset.modalId) {
      this.close();
    }
  }

  onNextFocusItem(ev) {
    if (this.opened) {
      const [elements, index] = this.focusableElementsAndIndex;

      ev.preventDefault();

      if (index < 0 || index === elements.length - 1) elements[0].focus();
      else elements[index + 1].focus();
    }
  }

  onPreviousFocusItem(ev) {
    if (this.opened) {
      const [elements, index] = this.focusableElementsAndIndex;

      ev.preventDefault();

      if (index === 0) elements[elements.length - 1].focus();
      else elements[index - 1].focus();
    }
  }

  onEscape(ev) {
    if (this.opened) {
      ev.preventDefault();

      this.close();
    }
  }
}
