import { Controller } from 'stimulus';
import { useClickOutside, useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = [
    'form', 'help', 'next', 'nextButton', 'nextOpenDate',
    'notAvailableLabel', 'notAvailableDate', 'notAvailableDateInput',
    'popup', 'roleType', 'hoursPerWeek'
  ];

  get checked() {
    return [...this.element.querySelectorAll('input:checked')];
  }

  connect() {
    useClickOutside(this);
    useTransition(this, { element: this.popupTarget });
  }

  clickOutside() {
    this.reset();
  }

  toggle(ev) {
    if (this.checked.length > 0) {
      this.helpTarget.classList.add('hidden');
      this.nextTarget.classList.remove('hidden');
    } else {
      this.nextTarget.classList.add('hidden');
      this.helpTarget.classList.remove('hidden');
    }
  }

  nextStep() {
    this.nextButtonTarget.classList.add('hidden');
    this.roleTypeTargets.forEach(label => label.classList.add('hidden'));
    this.hoursPerWeekTargets.forEach(label => label.classList.remove('hidden'));
  }

  notAvailableStep(ev) {
    this.nextButtonTarget.classList.add('hidden');
    this.roleTypeTargets.forEach(label => {
      if (label !== ev.currentTarget)
        label.classList.add('hidden');
    });

    this.notAvailableLabelTarget.classList.add('hidden');
    this.notAvailableDateTarget.classList.remove('hidden');
  }

  submit() {
    this.formTarget.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
  }

  submitNotAvailable() {
    const { value } = this.nextOpenDateTarget;

    if (value && value !== '') {
      this.notAvailableDateInputTarget.value = value;
      this.notAvailableDateInputTarget.form.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
    }
  }

  async reset() {
    if (this.reseting)
      return;

    this.reseting = true;
    this.element.classList.add('pointer-events-none');

    await this.leave();

    this.checked.forEach(input => input.checked = false);

    this.nextButtonTarget.classList.remove('hidden');
    this.notAvailableLabelTarget.classList.remove('hidden');
    this.roleTypeTargets.forEach(label => label.classList.remove('hidden'));
    this.hoursPerWeekTargets.forEach(label => label.classList.add('hidden'));
    this.notAvailableDateTarget.classList.add('hidden');

    this.element.classList.remove('pointer-events-none');

    delete this.reseting;
  }
}
