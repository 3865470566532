import { Controller } from 'stimulus';
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = [ 'rateAmount', 'feePercent', 'feeAmount', 'totalAmount' ]
  static values = {
    currencyCode: String,
    percent: Number
  };

  connect() {
    const autoNumericOptionsUSD = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '\u202fUS$',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'B',
      decimalPlaces              : 2,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    const autoNumericOptionsCAD = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '\u202fC$',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'B',
      decimalPlaces              : 2,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    const autoNumericOptionsAUD = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '\u202fA$',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'B',
      decimalPlaces              : 2,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    const autoNumericOptionsGBP = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '\u202f£',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'B',
      decimalPlaces              : 2,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    var currencyCode = this.currencyCodeValue.toLowerCase()
    switch(currencyCode) {
      case 'usd':
        this.autoNumericOptions = autoNumericOptionsUSD
        break;
      case 'cad':
        this.autoNumericOptions = autoNumericOptionsCAD
        break;
      case 'aud':
        this.autoNumericOptions = autoNumericOptionsAUD
        break;
      case 'gbp':
        this.autoNumericOptions = autoNumericOptionsGBP
        break;
      default:
        this.autoNumericOptions = autoNumericOptionsUSD
    }

    new AutoNumeric(this.rateAmountTarget, this.autoNumericOptions)
    new AutoNumeric(this.feeAmountTarget, this.autoNumericOptions)
    new AutoNumeric(this.totalAmountTarget, this.autoNumericOptions)
  }

  // when changing the rate
  updateFeeAmountByPercentOfRate() {
    const feeAmount = (this.rateAmount() * (this.feePercentTarget.value / 100))
    AutoNumeric.getAutoNumericElement(this.feeAmountTarget).set(feeAmount)
  }

  updateTotalAmount() {
    this.previousAdjustment = 'rate'
    this.updateFeeAmountByPercentOfRate()
    const totalAmount = this.rateAmount() + this.feeAmount()
    AutoNumeric.getAutoNumericElement(this.totalAmountTarget).set(totalAmount)
  }


  // when changing the total
  updateRateAmount() {
    this.previousAdjustment = 'total'
    const rateAmount = this.totalAmount() / ( 1 + (this.feePercentTarget.value / 100) )
    const rateAmountRounded = Math.round((rateAmount + Number.EPSILON) * 100) / 100
    AutoNumeric.getAutoNumericElement(this.rateAmountTarget).set(rateAmountRounded)
    this.updateFeeAmountBySubtraction()
  }

  // depending on which value the user has previously adjusted
  // we assume they want to see the change in other values if fee percent is adjusted
  updateFeePercent() {
    if (this.previousAdjustment === 'rate') {
      this.updateTotalAmount()
    } else {
      this.updateRateAmount()
    }
  }

  updateFeeAmountBySubtraction() {
    const feeAmount = this.totalAmount() - this.rateAmount()
    AutoNumeric.getAutoNumericElement(this.feeAmountTarget).set(feeAmount)
  }

  rateAmount() {
    return AutoNumeric.getAutoNumericElement(this.rateAmountTarget).getNumber()
  }

  feeAmount() {
    return AutoNumeric.getAutoNumericElement(this.feeAmountTarget).getNumber()
  }

  totalAmount() {
    return AutoNumeric.getAutoNumericElement(this.totalAmountTarget).getNumber()
  }

  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.rateAmountTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.feeAmountTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.totalAmountTarget).formUnformat()
  }

  set previousAdjustment(value) {
    this.data.set('previousAdjustment', value)
  }

  get previousAdjustment() {
    return this.data.get('previousAdjustment')
  }
}
