import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['addedBox', 'removedBox'];

  connect() {
  }

  remove(ev) {
    const { currentTarget: { id } } = ev;

    this.updateSelfRemoved(id)
    this.updateRemovedList(id)
  }

  add(ev) {
    const { currentTarget: { id } } = ev;

    this.updateSelfAdded(id)
    this.updateAddedList(id)
  }

  updateSelfRemoved(id) {
    this.addedBoxTargets.forEach(addedBox => {
      if (addedBox.id == id) {
        addedBox.classList.add('hidden')
      }
    })
  }

  updateSelfAdded(id) {
    this.removedBoxTargets.forEach(removedBox => {
      if (removedBox.id == id) {
        removedBox.classList.add('hidden')
      }
    })
  }

  updateRemovedList(id) {
    this.removedBoxTargets.forEach(removedBox => {
      if (removedBox.id == id) {
        removedBox.classList.remove('hidden')
      }
    })
  }

  updateAddedList(id) {
    this.addedBoxTargets.forEach(addedBox => {
      if (addedBox.id == id) {
        addedBox.classList.remove('hidden')
      }
    })
  }

}
