import { Controller } from 'stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['select', 'hiddenSection'];
  static values = {
    trigger: String
  }

  connect() {
    useTransition(this, {
      element: this.hiddenSectionTarget,
      transitioned: false,
    });

    const selectedValue = this.selectTarget.options[this.selectTarget.selectedIndex].value

    if (selectedValue === this.triggerValue) {
      this.enter()
    } else {
      this.leave()
    }
  }

  selectChanged(ev) {
    const { target: { value } } = ev;

    if (value === this.triggerValue) {
      this.enter()
    } else {
      this.leave()
    }
  }

  hiddenSelectChanged() {
    const selectedValue = this.selectTarget.options[this.selectTarget.selectedIndex].value

    if (selectedValue === this.triggerValue) {
      this.enter()
    } else {
      this.leave()
    }
  }
}
