import copy from 'copy-to-clipboard';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['message', 'form'];

  static values = {
    copy: String
  };

  copy(ev) {
    ev.preventDefault();

    copy(this.copyValue);

    if (this.hasMessageTarget) {
      this.messageTarget.classList.remove('opacity-0');

      setTimeout(() => {
        this.messageTarget.classList.add('opacity-0');
      }, 3000);
    }
  }

  copyFormLocation(ev) {
    if (this.hasFormTarget) {
      const formData = new FormData(this.formTarget);
      const formEntries = [...formData.entries()]
        .filter(([ _, value ]) => value.length > 0)
        .map(x => `${x[0]}=${x[1]}`)

      const search = formEntries.join('&');

      copy(`${window.location.href}?${search}`);
    }
  }
}
