import { DataMap } from '@stimulus/core/dist/data_map';

export const useSyncFrame = (controller, options = {}) => {
  const identifier = 'sync-event';

  const reloadFrame = async (frame, timestamp) => {
    const frameDataMap = new DataMap({ element: frame, identifier });

    const framePath = frameDataMap.get('path');
    const frameTimestamp = frameDataMap.get('timestamp');

    // console.debug('<turbo-frame> sync', frame);

    // The turbo-frame needs to be reload via it's src attribute
    if (!frameTimestamp || isStale(timestamp, frameTimestamp)) {
      // console.debug('<turbo-frame> reload', framePath, frameTimestamp);

      await frame.loaded;

      if (frame.src)
        frame.src = null;

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          frame.src = framePath;
        });
      });
    }
  }

  Object.assign(controller, { reloadFrame });
};
