import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'input', 'button'];
  static values = {
    initSelected: Array,
  };

  connect() {
  }

  getSelected() {
    var selected = [];

    var checkboxes = this.checkboxTargets;

    for (var i = 0; i < checkboxes.length; i++) {
      var checkbox = checkboxes[i];

      if (checkbox.checked) selected.push(checkbox.value);
    }

    return selected;
  }

  updateSelected() {
    this.selectedArray = this.getSelected();

    if (this.checkEquals(this.selectedArray, this.initSelectedValue)) {

      this.inputTarget.style.transition = 600 + 'ms';
      this.inputTarget.style.opacity = 1;
      this.buttonTarget.style.transition = 600 + 'ms';
      this.buttonTarget.style.opacity = 0;
      window.setTimeout( () => {
            this.inputTarget.classList.remove('hidden');
            this.buttonTarget.classList.add('hidden');
      }, 600);

    } else {

      this.inputTarget.style.transition = 600 + 'ms';
      this.inputTarget.style.opacity = 0;
      this.buttonTarget.style.transition = 600 + 'ms';
      this.buttonTarget.style.opacity = 1;
      window.setTimeout( () => {
            this.buttonTarget.classList.remove('hidden');
            this.inputTarget.classList.add('hidden');
      }, 600);

    }
  }

  checkEquals(a, b) {
    a.sort();
    b.sort();

    return a.length === b.length && a.every((v, i) => v === b[i]);
  }
}
