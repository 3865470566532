import { computePosition, autoPlacement, offset, shift, size } from '@floating-ui/dom';

export const useFloatingPopups = (controller, options = {}) => {
  const targetName = 'popupTarget';

  const apply = ({ x, y, strategy }) => {
    Object.assign(controller[targetName].style, {
      left: `${x}px`, top: `${y}px`, position: strategy
    });
  }

  const sizeApply = ({ reference }) => {
    controller[targetName].style.width = `${reference.width}px`;
  };

  const displace = (...args) => {
    const { offsetParent } = controller.element;

    const displaceElement = options.displaceSelector ?
      controller.element.querySelector(options.displaceSelector) : null;

    if (!offsetParent)
      return 0;

    if (offsetParent.className === 'h-full relative') {
      const rect = offsetParent.getBoundingClientRect();
      const { y } = rect;

      return (-(y + document.documentElement.scrollTop) + 10);
    } else if (offsetParent.dataset.id === 'admin-modal-body') {
      const top = offsetParent.scrollTop;

      if (top) {
        return -50 + top;
      } else {
        return -50;
      }
    } else if (offsetParent.dataset.id === 'modal-body') {
      const top = offsetParent.scrollTop;

      if (top) {
        return -(offsetParent.previousElementSibling.offsetHeight) + 10 + top;
      } else {
        return -(offsetParent.previousElementSibling.offsetHeight) + 10 ;
      }
    } else if (displaceElement) {
      return -(displaceElement.clientHeight);

    } else {
      return 10;
    }
  };

  const positionPopup = () => {
    // Never reposition when already visible
    if (!controller[targetName].classList.contains('hidden'))
      return;

    computePosition(controller.element, controller[targetName], {
      middleware: [
        autoPlacement({ alignment: 'bottom-start', allowedPlacements: ['bottom-start'] }),
        size({ apply: sizeApply }),
        offset(displace)
      ]
    }).then(apply);
  };

  Object.assign(controller, {
    get scrollRoot() {
      let { element } = controller;

      while (element !== document.body) {
        if (element.classList.contains('overflow-y-auto') || element.classList.contains('overflow-y-scroll'))
          return element;

        element = element.parentElement;
      }

      return element;
    },

    positionPopup
  });
};
