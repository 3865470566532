import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lever', 'onView', 'offView'];
  static values = {
    initStatus: String
  }

  connect() {
    if (this.initStatusValue == "on") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  toggle() {
    if (this.status == "off") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  turnOn() {
    this.moveLeverRight()
    this.showOn()
    this.status = "on"
  }

  turnOff() {
    this.moveLeverLeft()
    this.showOff()
    this.status = "off"
  }

  moveLeverRight() {
    this.leverTarget.classList.add('translate-x-5')
    this.leverTarget.classList.remove('translate-x-0')
  }

  moveLeverLeft() {
    this.leverTarget.classList.remove('translate-x-5')
    this.leverTarget.classList.add('translate-x-0')
  }

  showOn() {
    this.onViewTarget.classList.remove('hidden')
    this.offViewTarget.classList.add('hidden')
  }

  showOff() {
    this.onViewTarget.classList.add('hidden')
    this.offViewTarget.classList.remove('hidden')
  }
}
