import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['updateInput'];

  static targets = ['copyInput','input'];

  connect() {
    useDebounce(this);

    this.setCopyInputs()
  }

  updateInput(ev) {
    const { target } = ev;

    this.copyInputTargets.forEach((copyInput) => {
      if (copyInput.id.includes(target.id.concat("-copy"))) {
        if (target.checked) {
          copyInput.checked = true
        } else {
          copyInput.checked = false
        }
      }
    })
  }

  setCopyInputs(){
    this.inputTargets.forEach((input) => {
      this.copyInputTargets.forEach((copyInput) => {
        if (copyInput.id.includes(input.id.concat("-copy"))) {
          if (input.checked) {
            copyInput.checked = true
          } else {
            copyInput.checked = false
          }
        }
      })
    });
  }
}
