import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

const filters = {
  first: x => x.split(' ', 2).shift(),
  none: x => x,
};

// TODO add support for multiple fields
export default class extends Controller {
  static debounces = ['updateInput'];

  static targets = ['input'];

  static values = {
    filter: String
  };

  connect() {
    useDebounce(this);
  }

  updateInput(ev) {
    const { target } = ev;

    if (this.hasInputTarget) {
      this.inputTarget.value = filters[this.filterValue || 'none'](target.value);
    }
  }
}
