import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'input'];

  show(ev) {
    ev.currentTarget.remove();

    this.buttonTarget.classList.remove('opacity-0');
    this.inputTarget.classList.remove('opacity-0');
    this.inputTarget.focus();
  }
}
