import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['updateInput'];

  static targets = ['copyInput','input'];

  connect() {
    useDebounce(this);

    this.setCopyInputs()
  }

  updateInput(ev) {
    const { target } = ev;

    this.copyInputTargets.forEach((copyInput) => {
      if (copyInput.id.includes(target.id.concat("-copy"))) {
        copyInput.value = target.value;
      }
    })
  }

  setCopyInputs(){
    this.inputTargets.forEach((input) => {
      this.copyInputTargets.forEach((copyInput) => {
        if (copyInput.id.includes(input.id.concat("-copy"))) {
          copyInput.value = input.value;
        }
      })
    });
  }
}
