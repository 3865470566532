import {Controller} from "stimulus"

const RADIO_BUTTON_TOGGLE = 'radio-button-selection-toggle'

export default class extends Controller {
  static targets = ["section"]

  static values = {
    initButton: Number
  }

  connect() {
    document.addEventListener(RADIO_BUTTON_TOGGLE, this.handleSelection.bind(this));
    this.sectionTargets.forEach((section) => {
      if (section.id == this.initButtonValue) {
        section.classList.remove('hidden')
      } else {
        section.classList.add('hidden')
      }
    });
  }

  handleSelection(event) {
    this.sectionTargets.forEach((section) => {
      if (section.id == event.detail.value) {
        section.classList.remove('hidden')
      } else {
        section.classList.add('hidden')
      }
    });
  }
}
