import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    trigger: String
  }

  connect() {
  }

  radioChanged(ev) {
    const { target: { value } } = ev;

    if (value !== this.triggerValue) {
      this.inputTarget.value = ""
    }
  }
}
