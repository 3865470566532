import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'checkImage', 'addImage'];
  static values = {
    initStatus: String
  }

  connect() {
    this.initAddChecks(this.inputTargets)
  }

  toggle(ev) {
    const { target } = ev;

    this.setAddChecks(target);
  }

  initAddChecks(inputTargets) {
    inputTargets.forEach((target) => {
      this.setAddChecks(target);
    })
  }

  setAddChecks(target) {
    this.checkImageTargets.forEach((checkImage) => {
      if (checkImage.id.includes(target.id)) {
        if (target.checked) {
          checkImage.classList.remove('hidden')
        } else {
          checkImage.classList.add('hidden')
        }
      }
    })

    this.addImageTargets.forEach((addImage) => {
      if (addImage.id.includes(target.id)) {
        if (target.checked) {
          addImage.classList.add('hidden')
        } else {
          addImage.classList.remove('hidden')
        }
      }
    })
  }
}
