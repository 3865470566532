import { Controller } from 'stimulus';
import { computePosition, autoPlacement, offset } from '@floating-ui/dom';

import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static values = {
    bg: String, text: String
  };

  connect() {
    const bg = this.bgValue || 'bg-gray-900';

    this.popupElement = document.createElement('div');
    this.popupElement.innerHTML = this.textValue;
    this.popupElement.className = `transition-opacity opacity-0 ${bg} rounded-md shadow-lg px-2 py-1 ` +
      'text-p5 md:text-p4 text-center leading-p5 text-white absolute top-0 left-0 max-w-[40ch] width-[max-content] pointer-events-none z-50';

    this.listeners = [
      new ElementListener(this.element, 'mouseenter', this.show),
      new ElementListener(this.element, 'mouseleave', this.hide),
      new ElementListener(this.element, 'tap', this.show),
    ];

    document.body.appendChild(this.popupElement);

    requestAnimationFrame(() => {
      this.positionPopup();
    });
  }

  disconnect() {
    this.popupElement.remove();
    this.listeners.forEach(l => l.remove());
  }

  show = () => {
    this.positionPopup()
    this.visible = true;

    setTimeout(() => {
      if (this.visible) {
        this.popupElement.classList.remove('opacity-0');
      }
    }, 300);
  }

  hide = () => {
    this.visible = false;
    this.popupElement.classList.add('opacity-0');
  }

  positionPopup() {
    computePosition(this.element, this.popupElement, {
      middleware: [autoPlacement(), offset(10)],
    }).then(({ x, y, strategy }) => {
      Object.assign(this.popupElement.style, {
        left: `${x}px`, top: `${y}px`, position: strategy
      });
    });
  }
}
