import { Controller } from 'stimulus';
import { useDebounce, useDispatch } from 'stimulus-use';

import { useAutoFocusable } from '../mixins';
import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static debounces = ['submit'];
  static targets = ['invisible'];

  static values = {
    keepOpen: Boolean
  };

  get formElement() {
    return this.element.querySelector('form');
  }

  connect() {
    this.listener = new ElementListener(document.documentElement, 'turbo:submit-end', this.onTurboSubmitEnd);

    useAutoFocusable(this);
    useDebounce(this);
    useDispatch(this);
  }

  disconnect() {
    this.listener.remove();
  }

  resetRadios(ev) {
    ev.preventDefault();

    const input = ev.currentTarget.parentElement.querySelector('input[type="radio"]:checked');

    if (input) {
      input.checked = false
    }
  }

  radioChanged(ev) {
    const { target: { name, value } } = ev;

    [...this.formElement.querySelectorAll(`input[data-radio-name="${name}"]`)].forEach(input => {
      if (input.dataset.radioValue === value) {
        input.classList.add('opacity-100');
      } else {
        input.classList.remove('opacity-100');
      }
    });
  }

  toggleDisabledWithChecked(ev) {
    const { target } = ev;
    const { dataset: { fieldName } } = target;

    if (fieldName) {
      const input = target.form.querySelector(`input[name="${fieldName}"]`);

      input.disabled = target.checked;
    }
  }

  submit = (ev) => {
    this.formElement.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
  }

  onTurboSubmitEnd = (event) => {
    if (this.keepOpenValue === true)
      return;

    if (event.detail.success && event.detail.formSubmission.formElement === this.formElement) {
      this.dispatch('close');
    }
  }
}
