import { Controller } from 'stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = [
    'checkbox', 'input', 'inputalt'
  ];

  connect() {
    useDispatch(this);

    this.toggle();
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.inputTarget.classList.add('hidden')

      if (this.hasInputaltTarget)
        this.inputaltTarget.classList.remove('hidden')
    } else {
      this.inputTarget.classList.remove('hidden')

      if (this.hasInputaltTarget)
        this.inputaltTarget.classList.add('hidden')
    }

    this.dispatch('toggled');
  }
}
