import { Controller } from 'stimulus';
import { useMatchMedia } from 'stimulus-use';

export default class extends Controller {
  static targets = ['button'];
  static values = {
    collapsed: Boolean, copy: String,
  };

  get paragraphElements() {
    return [...this.element.querySelectorAll('p:nth-child(n+2)')];
  }

  connect() {
    this.initialCopy = this.buttonTarget.innerText;

    if (this.hasCollapsedValue && this.collapsedValue) {
      this.collapse();
    }

    useMatchMedia(this, {
      mediaQueries: {
        small: '(max-width: 640px)'
      }
    });
  }

  isSmall() {
    this.collapse();
  }

  toggle() {
    if (this.state === 'collapsed') {
      this.expand();
    } else {
      this.collapse();
    }
  }

  collapse() {
    const pNodes = this.paragraphElements;
    const heights = pNodes.map(p => p.offsetHeight);

    if (pNodes.length < 1) {
      this.buttonTarget.classList.add('hidden');

      return;
    }

    pNodes.forEach(p => {
      p.classList.add('transition-all', 'overflow-hidden');
      p.style.height = 0;
      p.style.marginTop = 0;
      p.style.marginBottom = 0;
    });

    this.buttonTarget.innerText = this.initialCopy;
    this.buttonTarget.scrollIntoView();
    this.state = 'collapsed';
  }

  expand() {
    this.paragraphElements.forEach(p => {
      p.style.height = p.style.marginTop = p.style.marginBottom = null;
    });

    this.buttonTarget.innerText = this.copyValue;
    this.state = 'expanded';
  }
}
