import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container', 'image', 'indicator'
  ];

  connect() {
    this.observer = new IntersectionObserver(this.updateIndicators, {
      root: this.containerTarget, threshold: 0.5
    })

    this.imageTargets.forEach(image => this.observer.observe(image));
  }

  scrollTo(ev) {
    const { currentTarget } = ev;
    const index = [...currentTarget.parentElement.children].indexOf(currentTarget);
    const image = this.containerTarget.children[index];

    image.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  updateIndicators = (entries) => {
    if (!this.hasIndicatorTarget)
      return;

    entries.forEach(entry => {
      const intersectingIndex = this.imageTargets.indexOf(entry.target);
      const indicator = this.indicatorTargets[intersectingIndex];

      if (entry.intersectionRatio > 0.5) {
        indicator.classList.add('bg-primary-700');
        indicator.classList.remove('bg-primary-100');
      } else {
        indicator.classList.add('bg-primary-100');
        indicator.classList.remove('bg-primary-700');
      }
    })
  }
}
