import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    appId: String,
    apiKey: String,
    detached: Boolean,
    filters: String,
    websiteUri: String
  };

  connect() {
    const searchClient = algoliasearch(this.appIdValue, this.apiKeyValue);
    const queryParams = {}
    const websiteUri = this.websiteUriValue;

    if (this.hasFiltersValue) {
      queryParams.filters = this.filtersValue
    }

    const options = {
      container: this.element,

      classNames: {
        root: 'rounded-lg',
        form: 'rounded-lg',
        input: 'rounded-r-lg border-gray-700 focus:border-primary-500 focus:ring-primary-500',
        inputWrapper: 'rounded-lg',
        list: 'divide-y divide-fawn-700',
        detachedSearchButton: 'rounded-md border-transparent',
        detachedSearchButtonIcon: 'text-gray-900 hover:text-primary-700'
      },

      placeholder: '',

      getSources() {
        return [{
          sourceId: 'pages',

          getItems({ query }) {
            return getAlgoliaResults({
              searchClient, queries: [{
                indexName: 'publicist', query, ...queryParams
              }],
            });
          },

          getItemUrl({ item }) {
            return `${location.origin}${item.slug}`;
          },

          templates: {
            item({ item, html }) {
              return html`<a href=${`${websiteUri}${item.slug}`} class="py-4" target="_blank">
                <div class="flex justify-between items-start gap-8">
                  <div class="shrink">
                    <p class="text-p3 leading-p3 text-primary-700">${item.title}</p>
                  </div>

                  <div>
                    <span class="text-p5 leading-p5 bg-highlight-opal text-primary-700 rounded-lg inline-flex items-center gap-2 px-3 py-1 text-p5 leading-none uppercase whitespace-nowrap">
                      ${item.facet}
                    </span>
                  </div>
                </div>
                <p class="text-p4 leading-p4 text-gray-900">${item.description}</p>
              </a>`;
            },

            noResults({ html }) {
              return html`
                <div class="py-4 space-y-4">
                  <p class="text-p2 leading-p2 text-primary-700">No results found.</p>
                </div>
              `;
            }
          },
        }];
      }
    };

    if (this.detachedValue) {
      options.detachedMediaQuery = ''; // always in detached mode
    }

    this.autocomplete = autocomplete(options);
  }

  disconnect() {
    if (this.autocomplete) {
      this.autocomplete.destroy();
    }
  }
}
