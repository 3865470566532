import { Controller } from 'stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ['button', 'circle', 'svg'];

  get msAfterConnected() {
    return Date.now() - this.connectedAt;
  }

  connect() {
    const duration = 5000;
    const start = Date.now();

    let tick = 0;

    useDispatch(this);

    this.connectedAt = Date.now();
    this.scheduleTick();
  }

  scheduleTick() {
    setTimeout(() => {
      this.svgTarget.classList.remove('hidden');
      this.tick()
    }, 100);
  }

  setProgress(percentage) {
    const circle = this.circleTarget;

    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    const offset = circumference - percentage * circumference;

    circle.style.strokeDashoffset = offset;
  }

  tick() {
    const ms = this.msAfterConnected;

    if (ms > 5000) {
      this.setProgress(1);
      this.buttonTarget.classList.add('animate-pulse', 'animation-delay-100');

      setTimeout(() => {
        this.dispatch('close');
      }, 1000);
    } else {
      this.setProgress(ms / 5000);
      this.scheduleTick();
    }
  }
}
