export default class ElementListener {
  constructor(element, eventName, callback) {
    this.callback = (...args) => callback(...args);
    this.eventName = eventName;
    this.element = element;
    this.element.addEventListener(this.eventName, this.callback, false);
  }

  remove() {
    this.element.removeEventListener(this.eventName, this.callback, false);
  }
}
