import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lever', 'checkBox'];
  static values = {
    initStatus: String
  }

  connect() {
    if (this.initStatusValue == "on") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  toggle() {
    if (this.status == "off") {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  turnOn() {
    this.checkBoxTarget.checked = true
    this.status = "on"
  }

  turnOff() {
    this.checkBoxTarget.checked = false
    this.status = "off"
  }
}
