import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['iconEmpty', 'iconFilled', 'text', 'line'];
  static values = {
    index: String
  };

  connect() {
    let index = Number(this.indexValue)

    this.iconEmptyTargets.forEach(icon => {
      let key =  Number(icon.getAttribute("data-stepper-progress-index-key"))

      if (key <= index) {
        icon.classList.add('hidden')
      } else {
        icon.classList.remove('hidden')
      }
    })

    this.iconFilledTargets.forEach(icon => {
      let key =  Number(icon.getAttribute("data-stepper-progress-index-key"))

      if (key <= index) {
        icon.classList.remove('hidden')
      } else {
        icon.classList.add('hidden')
      }
    })

    this.textTargets.forEach(text => {
      let key =  Number(text.getAttribute("data-stepper-progress-index-key"))

      if (key <= index) {
        text.classList.remove('text-kashmir-800')
        text.classList.add('text-juniper-900')
      } else {
        text.classList.add('text-kashmir-800')
        text.classList.remove('text-juniper-900')
      }
    })

    this.lineTargets.forEach(line => {
      let key =  Number(line.getAttribute("data-stepper-progress-index-key"))

      if (key <= index) {
        line.classList.remove('border-kashmir-800')
        line.classList.add('border-juniper-900')
      } else {
        line.classList.add('border-kashmir-800')
        line.classList.remove('border-juniper-900')
      }
    })
  }
}
