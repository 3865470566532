import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "source", "filterable"]

  connect() {
  }

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()

    if (!this.sourceTarget.value) {
      this.filterableTargets.forEach((el, i) => {
        el.classList.add("hidden")
      })
    } else {
      this.filterableTargets.forEach((el, i) => {
        let filterableKey =  el.getAttribute("data-filter-list-key")

        if (filterableKey.includes( lowerCaseFilterTerm )) {
          el.classList.remove("hidden")
        } else {
          el.classList.add("hidden")
        }
      })
    }
  }
}
