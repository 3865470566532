import { Controller } from 'stimulus';
import { useWindowResize } from 'stimulus-use';

export default class extends Controller {
  connect() {
    useWindowResize(this)

    setTimeout(() => {
      // TODO scroll on certain turbo nav?
      window.scrollTo(0, 1);

      this.updateVHOverride();
    }, 1);
  }

  windowResize() {
    this.updateVHOverride();
  }

  updateVHOverride() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
