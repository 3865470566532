import { Controller } from 'stimulus';

// TODO disable for sync frames within engagements
export default class extends Controller {
  static targets = ['svg'];

  get docEl() {
    return document.documentElement;
  }

  connect() {
    this.docEl.addEventListener('turbo:before-fetch-request', this.beforeFetchRequest, false);
    this.docEl.addEventListener('turbo:before-fetch-response', this.beforeFetchResponse, false);
    this.docEl.addEventListener('turbo:frame-render', this.beforeFrameRender, false);
  }

  disconnect() {
    this.docEl.removeEventListener('turbo:before-fetch-request', this.beforeFetchRequest, false);
    this.docEl.removeEventListener('turbo:before-fetch-response', this.beforeFetchResponse, false);
    this.docEl.removeEventListener('turbo:frame-render', this.beforeFrameRender, false);
  }

  showLoader = () => {
    //console.log('showLoader');

    this.svgTarget.classList.remove('is-spinning');

    requestAnimationFrame(() => {
      if (this.docEl.querySelector('#success-modal') != null)
        return;

      this.element.classList.add('opacity-100');
      this.svgTarget.classList.add('is-spinning');
    });
  }

  hideLoader = () => {
    //console.log('hideLoader');

    this.element.classList.remove('opacity-100');
  }

  beforeFetchRequest = ev => {
    //console.log(ev.type);

    clearTimeout(this.timer);

    this.timer = setTimeout(this.showLoader, 200);
  }

  beforeFetchResponse = ev => {
    //console.log(ev.type);

    clearTimeout(this.timer);

    this.hideLoader();
  }

  beforeFrameRender = ev => {
    //console.log(ev.type);

    this.hideLoader();
  }
}
