import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['other', 'selection'];

  connect() {
    this.hideOther(this.selectionTarget.value)
  }

  selectChanged(ev) {
    const { target: { value } } = ev;

    this.hideOther(value)
  }

  hideOther(value) {
    if (value == 'other') {
      this.otherTarget.classList.remove('hidden');
    } else {
      this.otherTarget.classList.add('hidden');
    }
  }
}
