import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];
  static values = {
    trigger: String
  }

  connect() {
  }

  selectChanged(ev) {
    const { target: { value } } = ev;

    if (value !== this.triggerValue) {
      this.inputTarget.value = ""
    }
  }

  hiddenSelectChanged() {
    const selectedValue = this.selectTarget.options[this.selectTarget.selectedIndex].value

    if (selectedValue !== this.triggerValue) {
      this.inputTarget.value = ""
    }
  }
}
