import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { profile: Object };

  get docEl() {
    return document.documentElement;
  }

  connect() {
    this.docEl.addEventListener('turbo:before-render', this.onBeforeRender, false);

    this.callIntercom('onUnreadCountChange', unreadCount => {
      if (unreadCount > 0) {
        this.callIntercom('showMessages');
      }
    });

    if (Object.keys(this.profileValue).length === 0)
      return;

    if (window.intercomSettings && !window.intercomSettings.booted) {
      this.bootIntercom();

      window.intercomSettings.booted = true;
    }
  }

  disconnect() {
    this.docEl.removeEventListener('turbo:before-render', this.onBeforeRender, false);
  }

  openMessage(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.callIntercom('showNewMessage');
  }

  openTour(ev) {
    const { tourId } = ev.target.dataset;

    if (tourId) {
      this.callIntercom('startTour', tourId);
    }
  }

  bootIntercom() {
    this.callIntercom('boot', this.profileValue);
  }

  callIntercom(method, arg) {
    const style = 'color:white; background-color: #0B6458; padding: 0.25em 0.5em';

    if (typeof arg !== 'function') {
      console.group(`Intercom %c${method}`, style);

      if (arg) {
        console.table({ ...arg });
      }

      console.groupEnd();
    }

    if (typeof window.Intercom === 'function') {
      try {
        window.Intercom(method, arg);
      } catch(error) {
        console.debug(error.message);
      }
    }
  }

  onBeforeRender = (ev) => {
    const newBody = ev.detail.newBody;
    const html = newBody && newBody.parentElement;

    if (html && html.dataset && html.dataset.intercomProfileValue) {
      if (this.docEl.dataset.intercomProfileValue !== html.dataset.intercomProfileValue) {
        this.profileValue = JSON.parse(html.dataset.intercomProfileValue);

        setTimeout(() => {
          this.bootIntercom();
        }, 100);
      }
    }
  }
}
