import { Controller } from 'stimulus';
import { useClickOutside, useHotkeys } from 'stimulus-use';

// TODO this is incomplete and should be connected with form inputs
export default class extends Controller {
  static targets = ['button', 'list', 'listItem'];
  static values = { itemFocusClass: String };

  get selectedListItem() {
    return this.listItemTargets.find(item => item.getAttribute('aria-selected') === 'true');
  }

  connect() {
    this.focusedIndex = -1;
    this.itemFocusClasses = this.itemFocusClassValue.split(' ');
    this.initialSelectedListItem = this.selectedListItem;

    useClickOutside(this);

    // https://headlessui.dev/react/listbox#accessibility-notes
    useHotkeys(this, {
      'Enter': [this.onFocus],
      'Space': [this.onFocus],
      'Down': [this.onNextMenuItem],
      'Up': [this.onPreviousMenuItem],
      'Right': [this.onNextMenuItem],
      'Left': [this.onPreviousMenuItem],
      'Escape': [this.onEscapePress],
      // TODO
      // add Home and End for first/last focus
      // A-Z and a-z for first item that matches keyboard input
    });
  }

  toggle() {
    if (this.focusedIndex === -1) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand() {
    this.buttonTarget.setAttribute('aria-expanded', 'true');
    this.buttonTarget.setAttribute('aria-controls', this.listTarget.id);

    this.listTarget.classList.remove('opacity-0', 'pointer-events-none');

    this.focusedIndex = [...this.listTarget.children].indexOf(this.selectedListItem);
  }

  collapse() {
    this.buttonTarget.setAttribute('aria-expanded', 'false');
    this.buttonTarget.removeAttribute('aria-controls');

    this.listTarget.classList.add('opacity-0', 'pointer-events-none');
    this.listTarget.removeAttribute('aria-activedescendant');

    this.focusedIndex = -1;
    this.updateFocus();

    this.initialSelectedListItem.setAttribute('aria-selected', 'true');
    this.initialSelectedListItem.classList.add(...this.itemFocusClasses);
  }

  clickOutside(ev) {
    if (this.focusedIndex > -1) {
      this.collapse();
    }
  }

  updateFocus() {
    this.listItemTargets.forEach(item => {
      item.classList.remove(...this.itemFocusClasses)
      item.removeAttribute('aria-selected');
    });

    if (this.focusedIndex > -1) {
      const item = this.listItemTargets[this.focusedIndex]

      this.listTarget.setAttribute('aria-activedescendant', item.id);

      item.setAttribute('aria-selected', 'true');
      item.classList.add(...this.itemFocusClasses);
      item.focus();
    }
  }

  onEscapePress() {
    this.collapse();
  }

  // TODO this is specific to anchor elements and links
  onFocus() {
    const item = this.listItemTargets[this.focusedIndex];

    if (item) {
      const anchor = item.querySelector('a');

      if (anchor)
        anchor.click();
    }
  }

  onNextMenuItem(ev) {
    if (this.focusedIndex > -1) {
      this.focusedIndex++;

      if (this.focusedIndex == this.listItemTargets.length) {
        this.focusedIndex = 0; // wrap focus around
      }

      ev.preventDefault();
      this.updateFocus();
    }
  }

  onPreviousMenuItem(ev) {
    if (this.focusedIndex > -1) {
      this.focusedIndex--;

      if (this.focusedIndex < 0) {
        this.focusedIndex = this.listItemTargets.length - 1; // wrap focus around
      }

      ev.preventDefault();
      this.updateFocus();
    }
  }
}
