import { Controller } from 'stimulus';
import { useDispatch, useVisibility } from 'stimulus-use';

import ElementListener from '../../lib/element_listener';
import { findParentWith } from '../../lib/dom';

export default class extends Controller {
  static targets = ['message'];

  get topMessage() {
    return this.messageTargets[0];
  }

  connect() {
    useDispatch(this);
    useVisibility(this);

    this.listener = new ElementListener(document.documentElement, 'turbo:before-stream-render', this.onBeforeStreamRender);

    this.secondsCounted = 0;

    this.frame = requestAnimationFrame(() => {
      this.showNextMessage();
      this.resume();
    });
  }

  disconnect() {
    this.listener.remove();

    cancelAnimationFrame(this.frame);
    clearInterval(this.timer);
  }

  pause() {
    clearInterval(this.timer);
  }

  resume() {
    clearInterval(this.timer);

    if (this.messageTargets.length > 0) {
      this.timer = setInterval(() => {
        this.secondsCounted++;
        this.tick();
      }, 1000);
    }
  }

  visible() {
    this.resume();
  }

  invisible() {
    clearInterval(this.timer);
  }

  showNextMessage() {
    const target = this.topMessage;

    if (target) {
      this.dispatch('open', { target });
    }
  }

  remove() {
    const target = this.topMessage;

    if (target) {
      this.dispatch('close', { target });
      this.frame = setTimeout(() => {
        target.remove();

        this.showNextMessage();
        this.resume();
      }, 110); // roughly after the transition
    }
  }

  tick() {
    if (this.secondsCounted === 6) {
      this.secondsCounted = 0;
      this.remove();
    }
  }

  onBeforeStreamRender = (ev) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        if (this.messageTargets.length > 0) {
          this.showNextMessage();
          this.resume();
        }
      });
    });
  }
}
