function readFile(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = ev => resolve(ev.target.result);
    reader.onerror = err => reject(err);

    reader.readAsDataURL(file);
  });
}

function loadImage(dataURI) {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = err => reject(err);

    img.src = dataURI;
  });
}

export async function loadImageFromFile(file, contraints = {}) {
  const img = await loadImage(await readFile(file));

  if (!contraints || !contraints.max)
    return img.src;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const { width, height } = img;

  let resizeHgt = height;
  let resizeWdt = width;

  if (width > height) {
    if (width > contraints.max) {
      resizeHgt = height * (contraints.max / width);
      resizeWdt = contraints.max;
    }
  } else {
    if (height > contraints.max) {
      resizeHgt = contraints.max;
      resizeWdt = width * (contraints.max / height);
    }
  }

  canvas.width = resizeWdt;
  canvas.height = resizeHgt;

  ctx.drawImage(img, 0, 0, resizeWdt, resizeHgt);

  return canvas.toDataURL(file.type);
}
