import { Controller } from 'stimulus';
import { useMeta } from 'stimulus-use';

import mixpanel from 'mixpanel-browser';

export default class extends Controller {
  static metaNames = ['mppt'];

  static values = {
    name: String, properties: Object
  };

  connect() {
    useMeta(this);

    if (window.location.pathname.startsWith('/admins/'))
      return;

    mixpanel.init(this.mpptMeta, { debug: this.application.__debug });
    mixpanel.track(this.nameValue, this.propertiesValue, {transport: 'sendBeacon'});

    const style = 'color:white; background-color: #022F4C; padding: 0.25em 0.5em';

    console.group(`Mixpanel Event %c${this.nameValue}`, style);

    if (Object.keys(this.propertiesValue).length > 0) {
      console.table(this.propertiesValue);
    }

    console.groupEnd();

    this.element.remove();
  }
}
