import { Controller } from "stimulus"

export default class extends Controller {
  // filesTarget is going to contain the list of input elements we've added
  // files to - these are the input elements that we're going to submit.
  static targets = ["files"]

  addFile(event) {
    // Grab some references for later
    const originalInput = event.target
    const originalParent = originalInput.parentNode

    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode()

    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = "";

    // Remove the "no files selected" text
    newInput.style.width = "97px";
    newInput.title = "";

    // Clear parent and add new input to the DOM where the original input was
    originalParent.innerHTML = "";
    originalParent.append(newInput)

    // Loop through all the files and let each one be its own input field
    this.files = Array.from(event.target.files)

    for (var i = 0; i < this.files.length; i++) {
      // Create an element that contains our input element
      let selectedFile = document.createElement("div")
      selectedFile.classList.add("border", "rounded-md", "p-4", "flex", "justify-between")

      let inputNode = document.createElement("input");
      inputNode.setAttribute('type', 'file');
      inputNode.setAttribute('multiple', 'multiple');
      inputNode.setAttribute('name', originalInput.name);
      inputNode.classList.add("hidden")
      selectedFile.append(inputNode)

      // Set that input to one of the files in the FileList
      let dt = new DataTransfer();
      dt.items.add(this.files[i])
      selectedFile.children[0].files = dt.files
      console.log(selectedFile.children[0].files)

      // Create label (the visible part of the new input element) with the name of
      // the file.
      let labelNode = document.createElement("label");
      let textElement = document.createTextNode(this.files[i].name);
      labelNode.appendChild(textElement);
      selectedFile.appendChild(labelNode);

      // Create trash can icon
      const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const iconPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );

      iconSvg.setAttribute('fill', 'none');
      iconSvg.setAttribute('viewBox', '0 0 24 24');
      iconSvg.setAttribute('stroke', 'currentColor');
      iconSvg.classList.add('w-5', 'h-5', 'text-indicator-red', "pointer-events-none");

      iconPath.setAttribute(
        'd',
        'M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
      );
      iconPath.setAttribute('stroke-linecap', 'round');
      iconPath.setAttribute('stroke-linejoin', 'round');
      iconPath.setAttribute('stroke-width', '2');

      iconSvg.appendChild(iconPath);

      // Create button with trash can
      let buttonNode = document.createElement("button");
      buttonNode.classList.add("items-center")
      buttonNode.setAttribute('data-action', "multi-upload#removeFile");
      buttonNode.setAttribute('type', "button");
      buttonNode.appendChild(iconSvg);
      selectedFile.appendChild(buttonNode);

      // Add the selected file to the list of selected files
      this.filesTarget.append(selectedFile)
    }
  }

  removeFile(event) {
    const selectedFile = event.target
    const selectedFileParent = selectedFile.parentNode

    selectedFileParent.remove()
  }
}
