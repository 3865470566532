import Cropper from 'cropperjs';
import { Controller } from 'stimulus';
import { useDispatch } from 'stimulus-use';

import { useDirectUpload } from '../mixins';
import { loadImageFromFile } from '../../lib/image';

export default class extends Controller {
  static targets = [
    'button', 'box', 'field', 'image', 'input', 'message', 'placeholder', 'preview'
  ];

  static values = {
    aspectRatio: Array, autoClose: Boolean
  };

  connect() {
    useDispatch(this);
    useDirectUpload(this);
  }

  get directUploadInput() {
    return this.inputTarget;
  }

  async load() {
    const [ file ] = this.inputTarget.files;

    try {
      const imgSrc = await loadImageFromFile(file, { max: 2400 });
      const [ aspectRatioWidth, aspectRatioHeight ] = this.hasAspectRatioValue ? this.aspectRatioValue : [1, 1]

      const data = {};

      if (this.hasBoxTarget) {
        Object.assign(data, {
          width: this.boxTarget.offsetWidth,
          height: this.boxTarget.offsetHeight,
        });

        this.boxTarget.style.height = `${data.height}px`;
        this.boxTarget.style.width = `${data.width}px`;
      }

      this.filename = file.name;
      this.imageTarget.src = imgSrc;

      if (this.hasPlaceholderTarget) {
        this.placeholderTarget.classList.add('hidden');
      }

      this.cropper = new Cropper(this.imageTarget, {
        aspectRatio: aspectRatioWidth / aspectRatioHeight
      });

      this.buttonTarget.classList.remove('opacity-0', 'pointer-events-none');
      this.inputTarget.value = '';
    } catch(err) {
      // TODO handle errors
      console.warn(err);
    }
  }

  async process(ev) {
    ev.preventDefault();

    this.cropper.getCroppedCanvas().toBlob(async (blob) => {
      this.buttonTarget.classList.add('is-loading');
      this.cropper.disable();

      const attachment = await this.upload('crop_' + this.filename, blob);

      this.buttonTarget.classList.remove('is-loading');

      this.fieldTarget.disabled = false;
      this.fieldTarget.value = attachment.signed_id;

      this.imageTarget.src = '';
      this.cropper.destroy();

      const { form } = this.fieldTarget;

      if (form) {
        form.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
      }

      if (!this.hasAutoCloseValue || this.autoCloseValue !== false) {
        setTimeout(() => {
          this.dispatch('close');
        }, 250);
      }
    })
  }
}
