import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  connect() {
  }

  clear() {
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false);
  }
}
