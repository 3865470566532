import { DirectUpload } from "@rails/activestorage";

export const useDirectUpload = (controller, options = {}) => {
  const delegate = {
    directUploadWillCreateBlobWithXHR(...args) {
      controller.dispatch('before-blob-request');
    },

    directUploadWillStoreFileWithXHR(...args) {
      controller.dispatch('before-storage-request');
    }
  };

  Object.assign(controller, {
    async upload(filename, blob) {
      // Ensure blob has a name for Rails' BlobRecord
      Object.assign(blob, {
        name: filename
      });

      return new Promise((resolve, reject) => {
        const directUploadUrl = this.directUploadInput.getAttribute("data-direct-upload-url");
        const directUploadToken = this.directUploadInput.getAttribute("data-direct-upload-token");
        const attachmentName = this.directUploadInput.getAttribute("data-direct-upload-attachment-name");

        const directUpload = new DirectUpload(
          blob, directUploadUrl, directUploadToken, attachmentName, delegate
        );

        directUpload.create((err, attachment) => {
          if (err) reject(err);
          else resolve(attachment);
        });
      });
    }
  });
};
