import { Controller } from 'stimulus';

import ElementListener from '../../lib/element_listener';

export default class extends Controller {
  static values = {
    interval: Number, src: String,
  }

  connect() {
    this.listener = new ElementListener(this.element, 'turbo:frame-render', this.onFrameRender);
    this.timeout = setInterval(() => {
      this.element.src = null;
      this.element.src = this.srcValue;
    }, this.intervalValue);
  }

  disconnect() {
    this.listener.remove();

    clearTimeout(this.timeout);
  }

  onFrameRender = () => {
    if (this.element.querySelector('[data-refresh-halt="true"]') !== null) {
      clearTimeout(this.timeout);
    }
  }
}
