import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label'];

  radioChanged(ev) {
    this.labelTargets.forEach((el, i) => {
      el.classList.toggle('bg-gray-500', ev.target.labels[0] == el )
    })
  }
}
