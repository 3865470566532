import { Controller } from 'stimulus';
import { useClickOutside, useDebounce, useMemo, useTransition } from 'stimulus-use';
import { useDispatch } from 'stimulus-use'

import { useFloatingPopups } from '../mixins';

import ElementListener from '../../lib/element_listener';

const STOP_WORDS = new RegExp(
  '^(i|we|it|its|itself|they|what|which|who|whom|this|that|these|those|am|is|are|was|were|be|been|being|have|has|had|having|do|does|did|doing|a|an|the|and|but|if|or|because|as|until|while|of|at|by|for|with|about|against|between|into|through|during|before|after|above|below|to|from|up|down|in|out|on|off|over|under|again|further|then|once|here|there|when|where|why|how|all|any|both|each|few|more|most|other|some|such|no|nor|not|only|own|same|so|than|too|very|can|will|just|should|now|they|them|their|theirs)$', 'i'
);

window.STOP_WORDS = STOP_WORDS;

export default class extends Controller {
  static debounces = [
    'performSearch', 'onNextFocusItem', 'onPreviousFocusItem', 'onScroll'
  ];

  static memos = ['optionTexts', 'optionValues'];
  static targets = [
    'arrow', 'limitMessage', 'input', 'template', 'option', 'suggested', 'pool', 'popup', 'placeholder'
  ];

  static values = {
    defaultTextClass: String,
    focusedBgClass: String,
    focusedTextClass: String,
    limit: Number,
    tagIds: Array,
    visible: String
  };

  get optionTexts() {
    return this.optionTargets.map(
      opt => opt.textContent.normalize('NFD')
        .replace(/\.,/g, '')
        .split(/\s+/)
        .filter(word => !STOP_WORDS.test(word))
        .join(' ').trim().toLowerCase()
    );
  }

  get optionValues() {
    return this.optionTargets.map(opt => +opt.dataset.value);
  }

  get focusedOptions() {
    return Array.from(this.element.querySelectorAll('li[aria-selected="true"]'));
  }

  connect() {
    this.availableIndexes = new Set();
    this.selectedIds = new Set(Array.from(this.tagIdsValue));
    this.focusedItemIndex = -1;

    useClickOutside(this);
    useDebounce(this);
    useFloatingPopups(this, { displaceSelector: 'fieldset' });
    useMemo(this);
    useTransition(this, { element: this.popupTarget });
    useDispatch(this)

    if (this.hasPlaceholderTarget && this.selectedIds.size < 1) {
      this.placeholderTarget.classList.remove('opacity-0');
    }

    this.selectedIds.forEach(id => {
      const node = this.element.querySelector(`li[data-value="${id}"]`);

      if (node) {
        this.addSelectedNode(node.dataset.label, id);
      }
    });

    this.limitMessageTarget.classList.add('hidden');
    this.updateLimit();

    this.listener = new ElementListener(this.scrollRoot, 'scroll', this.onScroll);

    requestAnimationFrame(() => {
      this.positionPopup();
    });
  }

  disconnect() {
    this.listener.remove();
  }

  addSelectedNode(label, value) {
    const node = this.templateTarget.content.cloneNode(true);

    node.querySelector('span').innerText = label;
    node.querySelector('button').value = value;
    node.querySelector('input').value = value;

    this.poolTarget.insertBefore(node, this.inputTarget);

    if (this.hasSuggestedTarget) {
      const suggestedButton = this.suggestedTarget.querySelector(`button[data-value="${value}"]`);

      if (suggestedButton) {
        suggestedButton.remove();
      }
    }
  }

  addSuggested(ev) {
    ev.preventDefault();

    const { target: { value } } = ev;

    addSe
  }

  changeInput() {
    const { value } = this.inputTarget;

    if (this.hasPlaceholderTarget) {
      if (value.length > 0) {
        this.placeholderTarget.classList.add('opacity-0');
      } else if (this.selectedIds.size < 1) {
        this.placeholderTarget.classList.remove('opacity-0');
      }
    }

    this.search = value.toString().trim().toLowerCase();

    this.performSearch();
  }

  async close() {
    this.focusedItemIndex = -1;
    this.inputTarget.value = '';
    this.search = undefined;

    if (this.hasPlaceholderTarget) {
      if (this.selectedIds.size < 1) {
        this.placeholderTarget.classList.remove('opacity-0');
      } else {
        this.placeholderTarget.classList.add('opacity-0');
      }
    }

    await this.leave();

    this.arrowTarget.classList.remove('rotate-180');
    this.arrowTarget.setAttribute('aria-expanded', 'false');

    // TODO this is not resetting scroll
    this.popupTarget.scrollTop = 0;
    this.positionPopup();
  }

  clickArrow() {
    if (this.search !== undefined) {
      this.close();

    } else {
      this.search = '';
      this.performSearch(true);
    }
  }

  clickOutside(ev) {
    if (this.search !== undefined) {
      ev.preventDefault();

      this.close();
    }
  }

  focusContainer() {
    this.inputTarget.focus();
  }

  hoverOption(ev) {
    if (this.scrolling !== undefined)
      return;

    const index = [...this.availableIndexes].indexOf(this.optionTargets.indexOf(ev.currentTarget));

    this.updateFocus(index);
  }

  keypressInput(ev) {
    if (ev.key === 'ArrowDown') {
      ev.preventDefault();

      this.onNextFocusItem();
    } else if (ev.key === 'ArrowUp') {
      ev.preventDefault();

      this.onPreviousFocusItem();
    } else if (ev.key === 'Enter') {
      ev.preventDefault();

      this.onFocusSelected(ev);
    } else if (ev.key === 'Backspace' && ev.currentTarget.value.length === 0) {
      ev.preventDefault();

      this.onBackspaceRemove();
    } else if (ev.key === 'Escape') {
      this.inputTarget.blur();
    }
  }

  updateLimit() {
    if (this.selectedIds.size <= this.limitValue) {
      this.inputTarget.disabled = false;
      this.limitMessageTarget.classList.add('hidden');
    } else {
      this.inputTarget.disabled = true;
      this.limitMessageTarget.classList.remove('hidden');
    }
  }

  performSearch(allowEmptySearch = false) {
    // Reset the available set
    this.availableIndexes.clear();

    if (this.search !== '' || allowEmptySearch === true) {
      this.optionTexts.forEach((text, index) => {
        if (!this.selectedIds.has(this.optionValues[index]) && text.includes(this.search)) {
          this.availableIndexes.add(index);
        }
      });
    }

    this.updateFocus(-1);
    this.renderMenu();
  }

  renderMenu() {
    if (this.renderId)
      cancelAnimationFrame(this.renderId);

    if (this.availableIndexes.size > 0) {
      this.arrowTarget.classList.add('rotate-180');
      this.arrowTarget.setAttribute('aria-expanded', 'true');

      this.enter();
    } else {
      this.arrowTarget.classList.remove('rotate-180');
      this.arrowTarget.setAttribute('aria-expanded', 'false');

      this.leave();
    }

    this.renderId = requestAnimationFrame(() => {
      this.optionTargets.forEach((opt, index) => {
        opt.className = (
          this.availableIndexes.has(index) ? this.visibleValue : 'hidden'
        );
      });

      delete this.renderId;
    });
  }

  async removeOption(ev) {
    ev.preventDefault();

    await this.close();

    if (ev.currentTarget) {
      this.selectedIds.delete(+ev.currentTarget.value);

      ev.currentTarget.parentElement.remove();

      this.updateLimit();
      this.positionPopup();
    }

    if (this.hasPlaceholderTarget && this.selectedIds.size < 1) {
      this.placeholderTarget.classList.remove('opacity-0');
    }
    this.dispatch('tag-removed')
  }

  async selectOption(ev) {
    ev.preventDefault();

    const { dataset: { value } } = ev.currentTarget;

    if (value !== '' || value !== null) {
      const id = +value;

      if (!this.selectedIds.has(id)) {
        this.selectedIds.add(id);
        this.addSelectedNode(ev.currentTarget.dataset.label, id);
        this.updateLimit();
      }

      await this.close();
    }
    this.dispatch('tag-selected')
  }

  // TODO refactor this to be less specific to DOM structure and the option
  // HTML
  updateFocus(newIndex, scrollTo = false) {
    this.focusedOptions.forEach(li => {
      const descNode = li.querySelector('p:nth-child(2)');

      if (descNode) {
        descNode.classList.remove(this.focusedTextClassValue);
        descNode.classList.add(this.defaultTextClassValue);
      }

      li.classList.remove(this.focusedBgClassValue, this.focusedTextClassValue);
      li.setAttribute('aria-selected', 'false');
    });

    if (newIndex === -1) {
      this.focusedItemIndex = newIndex;
      return;
    }

    const node = this.optionTargets[[...this.availableIndexes][newIndex]];

    if (!node)
      return;

    const descNode = node.querySelector('p:nth-child(2)');

    this.focusedItemIndex = newIndex;

    if (descNode) {
      descNode.classList.remove(this.defaultTextClassValue);
      descNode.classList.add(this.focusedTextClassValue);
    }

    node.classList.add(this.focusedBgClassValue, this.focusedTextClassValue);
    node.setAttribute('aria-selected', 'true');

    this.popupTarget.setAttribute('aria-activedescendant', node.id);

    if (scrollTo) {
      node.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

      if (this.scrolling)
        clearTimeout(this.scrolling);

      this.scrolling = setTimeout(() => {
        this.scrolling = undefined;
      }, 250);
    }
  }

  async onBackspaceRemove() {
    await this.close();

    if (this.selectedIds.size > 0) {
      this.selectedIds.delete([...this.selectedIds].pop());
      this.poolTarget.querySelector('div:last-of-type').remove();

      this.updateLimit();
      this.positionPopup();
    }
  }

  async onFocusSelected(ev) {
    const option = this.optionTargets[[...this.availableIndexes][this.focusedItemIndex]];

    if (option) {
      const id = +option.dataset.value;

      this.selectedIds.add(id);
      this.addSelectedNode(option.dataset.label, id);

      await this.close();
    }
  }

  onNextFocusItem = () => {
    this.updateFocus(this.focusedItemIndex + 1, true);
  }

  onPreviousFocusItem = () => {
    this.updateFocus(this.focusedItemIndex - 1, true);
  }

  onScroll = () => {
    this.positionPopup();
  }
}
