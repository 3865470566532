import {Controller} from "stimulus"

const RADIO_BUTTON_TOGGLE = 'radio-button-selection-toggle'

export default class extends Controller {
  static targets = ["section"]

  connect() {
    document.addEventListener(RADIO_BUTTON_TOGGLE, this.handleSelection.bind(this));
  }

  handleSelection(event) {
    this.sectionTargets.forEach((section) => {
      this.clearInputs(section)
      this.removeErrorMessages(section)
      this.resetSelects(section)
    });
  }

  clearInputs(section) {
    var inputs = section.querySelectorAll("input");
    inputs.forEach((input) => {
      input.value = null;
    })
  }

  removeErrorMessages(section) {
    var errors = section.querySelectorAll("[data-error]");
    errors.forEach((error) => {
      error.remove();
    })
  }

  resetSelects(section) {
    var selects = section.querySelectorAll("select");

    selects.forEach((select) => {
      select.selectedIndex =0;
    })
  }
}
