import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal'];

  connect() {
  }

  clear() {
    this.modalTarget.src = ""
  }
}
