import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'section', 'replacement'];

  connect() {
  }

  onClick() {
    this.replacementTarget.classList.remove('hidden')
    this.sectionTarget.classList.add('hidden')
  }
}
