import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['updateInput'];

  static targets = ['copyInput','button'];

  connect() {
    useDebounce(this);
  }

  updateInput(ev) {
    const { target } = ev;

    this.copyInputTarget.value = target.innerText;

    this.buttonTarget.click()
  }
}
