import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sectionOne', 'sectionTwo'];
  static values = {
    initView: String
  }

  connect() {
    if (this.initViewValue == "sectionTwo") {
      this.sectionTwoTarget.classList.remove('hidden');
      this.sectionTwoTarget.style.opacity = 1;

      this.sectionOneTarget.classList.add('hidden');
      this.sectionOneTarget.style.opacity = 0;

    };
  }

  viewSectionOne() {
    this.sectionTwoTarget.style.transitionProperty = 'height, margin, padding';
    this.sectionTwoTarget.style.transitionDuration = 500 + 'ms';
    this.sectionTwoTarget.style.boxSizing = 'border-box';
    this.sectionTwoTarget.style.height = this.sectionTwoTarget.offsetHeight + 'px';
    this.sectionTwoTarget.offsetHeight;
    this.sectionTwoTarget.style.overflow = 'hidden';
    this.sectionTwoTarget.style.height = 0;
    window.setTimeout( () => {
          this.sectionTwoTarget.style.display = 'none';
          this.sectionTwoTarget.style.removeProperty('height');
          this.sectionTwoTarget.style.removeProperty('overflow');
          this.sectionTwoTarget.style.removeProperty('transition-duration');
          this.sectionTwoTarget.style.removeProperty('transition-property');
          this.sectionTwoTarget.classList.add('hidden');
          this.sectionOneTarget.classList.remove('hidden');
    }, 500);
  }

  viewSectionTwo() {
    this.sectionOneTarget.classList.add('hidden');
    this.sectionTwoTarget.classList.remove('hidden');

    this.sectionTwoTarget.style.removeProperty('display');
    let display = window.getComputedStyle(this.sectionTwoTarget).display;
    if (display === 'none') display = 'block';
    this.sectionTwoTarget.style.display = display;
    let height = this.sectionTwoTarget.offsetHeight;
    this.sectionTwoTarget.style.overflow = 'hidden';
    this.sectionTwoTarget.style.height = 0;
    this.sectionTwoTarget.offsetHeight;
    this.sectionTwoTarget.style.boxSizing = 'border-box';
    this.sectionTwoTarget.style.transitionProperty = "height, margin, padding";
    this.sectionTwoTarget.style.transitionDuration = 500 + 'ms';
    this.sectionTwoTarget.style.height = height + 'px';
    window.setTimeout( () => {
      this.sectionTwoTarget.style.removeProperty('height');
      this.sectionTwoTarget.style.removeProperty('overflow');
      this.sectionTwoTarget.style.removeProperty('transition-duration');
      this.sectionTwoTarget.style.removeProperty('transition-property');
    }, 500);
  }
}
